import { Container, Nav, Navbar } from "react-bootstrap";
import styles from "./Header.module.css";
import me from "../static/images/me.jpg";
import { useEffect, useRef, useState } from "react";
import { Link, Element } from "react-scroll";
const Header = () => {
  const navbar = useRef();
  const [height, setHeight] = useState(60);
  useEffect(() => {
    setHeight(navbar.current.offsetHeight);
  }, [navbar]);
  return (
    <>
      <Navbar
        sticky="top"
        style={{
          zIndex: "9999",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)"
        }}
        ref={navbar}
        expand="lg"
        className={styles.navbar}
      >
        <Container>
          <Navbar.Brand href="#" className={styles.brand}>
            <span
              style={{
                fontWeight: "900"
              }}
            >
              µ
            </span>
            -sta<span>f</span>a
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto text-center">
              <Nav.Link
                as={Link}
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                href="#"
                className={styles.navLink}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="journey"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                href="#"
                className={styles.navLink}
              >
                Journey
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="skills"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                href="#"
                className={styles.navLink}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                href="#"
                className={styles.navLink}
              >
                Projects
              </Nav.Link>
              <Nav.Link disabled href="#experience" className={styles.navLink}>
                Coming soon
              </Nav.Link>
            </Nav>
            <div className="text-center">
              <a
                href="mailto:m@mu-stafa.com"
                className={`${styles.contactButton} mx-auto btn btn-dark`}
              >
                Contact Me
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Element name="home">
        <div
          className={`${styles.main} position-relative d-flex flex-column justify-content-center justify-items-center align-items-center align-content-center`}
          style={{
            height: `calc(100vh - ${height}px)`
          }}
        >
          <img
            src={me}
            className="position-absolute w-100 h-100"
            style={{
              objectFit: "cover",
              objectPosition: "top center",
              zIndex: "-1"
            }}
            alt="me"
          />
          <h1 className={`${styles.title} mx-2`}>Mustafa Abdelrahman</h1>
          <p className={`${styles.intro} lead mx-2`}>
            A Software Engineer from Cairo, contributed to innovation at{" "}
            <a href="https://eda.sw.siemens.com/" style={{ color: "#b8f2f2" }}>
              Siemens EDA
            </a>
            ,{" "}
            <a href="https://www.procore.com/" style={{ color: "#ffd3bf" }}>
              Procore Technologies
            </a>
            ,{" "}
            <a href="https://openstack.sh" style={{ color: "#a5a5ff" }}>
              bld.ai
            </a>
            , and more .
            <hr />
            <p className="text-bold">
              <strong>Explore my journey and work here.</strong>
            </p>
          </p>
        </div>
      </Element>
    </>
  );
};

export default Header;
