import github from "../images/github.png";
import openstack from "../images/openstack.png";
import udagram from "../images/udagram.png";
const projects = [
  {
    title: "DjMusic",
    subtitle: "Django Music",
    keywords: ["Django", "PostgreSQL"],
    text: "Django Web application with PostgreSQL as the database. This application enables users to effectively manage and administer artists and their albums, providing robust functionality for organizing, updating, and retrieving relevant data",
    username: "mustafausama",
    repository: "djmusic",
    image: github
  },
  {
    title: "Openstack.sh",
    subtitle: "Boilerplate builder tool",
    keywords: ["Confidential"],
    text: "OpenStack.sh is a platform offering prebuilt and customizable boilerplates for software egnineers and/or companies to quickly kickstart their product development",
    url: "https://openstack.sh/",
    image: openstack
  },
  {
    title: "Udagram-API-AWS",
    subtitle: "CI/CD Pipeline",
    keywords: ["Node.js", "Typescript", "Angular", "AWS", "CI/CD", "CircleCi"],
    text: "CircleCI CI/CD pipeline that automates the building, testing, and deployment processes for AWS Elastic Beanstalk and AWS S3, ensuring seamless and efficient delivery of the application",
    username: "mustafausama",
    repository: "udagram-api-aws",
    image: udagram
  },
  {
    title: "StorefrontAPI",
    subtitle: "Node.js/Typescript API",
    keywords: [
      "Node.js",
      "Typescript",
      "PostgreSQL",
      "Jasmine",
      "Testing",
      "JWT"
    ],
    text: "Store API built with Node.js and PostgreSQL ORM providing CRUD endpoints for efficient and secure data retrieval and manipulation",
    username: "mustafausama",
    repository: "storefrontAPI",
    image: github
  }
];

export default projects;
