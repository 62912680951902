import Header from "./Components/Header";
import Skills from "./Components/Skills";
import Timeline from "./Components/Timeline";
import Construction from "./Components/Construction";
import { Element } from "react-scroll";
import Projects from "./Components/Projects";

function App() {
  return (
    <div className="App">
      <Header />
      <Element name="journey">
        <Timeline />
      </Element>
      <Element name="skills">
        <Skills />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
      <Construction />
    </div>
  );
}

export default App;
