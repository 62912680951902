import { animateScroll as scroll } from "react-scroll";
import { HiOutlineArrowUturnUp } from "react-icons/hi2";
import { RiToolsFill } from "react-icons/ri";

const Construction = () => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      smooth: true,
      duration: 1000
    });
  };

  return (
    <>
      <div
        className="text-center d-flex justify-content-center align-items-center position-relative"
        style={{ height: "100vh", fontSize: "8em", backgroundColor: "#FFFFFF" }}
      >
        <RiToolsFill />
        <h2>Under construction</h2>
        <HiOutlineArrowUturnUp
          onClick={scrollToTop}
          className="position-absolute"
          style={{ bottom: "0", right: "0", cursor: "pointer" }}
        />
      </div>
    </>
  );
};

export default Construction;
