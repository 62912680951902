import React, { useState, useEffect } from "react";
import styles from "./Progress.module.css";

const Progress = ({ critical, start, color, reveal }) => {
  const criticalValue = critical;
  const targetColor = 30;
  const extreme = 0;
  const [progress, setProgress] = useState(start);
  const [hue, setHue] = useState(0);
  const [message, setMessage] = useState("Try to Drag");
  const [h, setH] = useState(0);
  const [s, setS] = useState(0);

  useEffect(() => {
    if (progress > criticalValue) {
      setHue(
        targetColor -
          (Math.abs(criticalValue - progress) / (100 - criticalValue)) *
            (targetColor - extreme)
      );
    } else {
      setHue(
        targetColor -
          (Math.abs(criticalValue - progress) / criticalValue) *
            (targetColor - extreme)
      );
    }

    const prompt = {
      0: "Drag to guess",
      15: "Drag more",
      50: "I'm better than this!!",
      70: "Almost there",
      85: "Around that value!",
      95: "Exactly!",
      105: "Will be there soon!!"
    };
    const val =
      100 - parseInt(((criticalValue - progress) / criticalValue) * 100);
    let closestKey = "0";
    for (const key in prompt)
      if (val >= key) closestKey = key;
      else break;
    setMessage(prompt[closestKey]);
  }, [progress, criticalValue]);

  useEffect(() => {
    if (color) {
      setH(color[0]);
      setS(color[1]);
    } else {
      const colors = [
        [207, 90],
        [340, 82],
        [283, 39],
        [168, 76],
        [141, 85],
        [28, 80],
        [6, 78]
      ];
      const r = parseInt(Math.random() * colors.length);
      setH(colors[r][0]);
      setS(colors[r][1]);
    }
    if (reveal) setProgress(criticalValue);
    else setProgress(start);
  }, [color, start, criticalValue, reveal]);

  const handleProgressChange = (e) => {
    const curr = Number(e.target.value);
    setProgress(curr);
  };

  return (
    <>
      <div className="position-relative w-100">
        <div
          className="progress"
          style={{ height: "20px", backgroundColor: "#bdc3c7" }}
        >
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{
              width: `${progress}%`,
              backgroundColor: `hsl(${h}, ${s}%, ${targetColor - hue}%)`,
              transitionProperty: "none",
              transitionDuration: "0"
            }}
            aria-valuenow={Number(progress)}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <input
          type="range"
          class={`${styles.slider} w-100 position-absolute`}
          style={{
            top: "0"
          }}
          max="100"
          value={progress}
          onChange={handleProgressChange}
        />

        <p
          style={{ color: `hsl(${h}, ${s}%, ${targetColor - hue}%)` }}
          class="fw-light"
        >
          {message}
        </p>
      </div>
    </>
  );
};

export default Progress;
