import { Container, Row, Col } from "react-bootstrap";
import styles from "./Skills.module.css";
import Progress from "./Progress";
import {
  SiJavascript,
  SiNodedotjs,
  SiPostgresql,
  SiPython,
  SiReact,
  SiTypescript
} from "react-icons/si";
import { TbBrandDjango, TbBrandGolang } from "react-icons/tb";
import { useState } from "react";

const Skills = () => {
  const [reveal, setReveal] = useState(false);
  const skills = [
    {
      name: "Javascript",
      level: 70,
      start: 10,
      icon: <SiJavascript />,
      color: [52, 78]
    },
    {
      name: "Typescript",
      level: 75,
      start: 10,
      icon: <SiTypescript />,
      color: [211, 60]
    },
    {
      name: "React.js",
      level: 85,
      start: 10,
      icon: <SiReact />,
      color: [188, 100]
    },
    {
      name: "Node.js",
      level: 85,
      start: 10,
      icon: <SiNodedotjs />,
      color: [109, 41]
    },
    {
      name: "Python",
      level: 70,
      start: 10,
      icon: <SiPython />,
      color: [45, 51]
    },
    {
      name: "Django",
      level: 80,
      start: 10,
      icon: <TbBrandDjango />,
      color: [157, 67]
    },
    {
      name: "PostgreSQL",
      level: 75,
      start: 10,
      icon: <SiPostgresql />,
      color: [207, 48]
    },
    {
      name: "Golang",
      level: 50,
      start: 10,
      icon: <TbBrandGolang />,
      color: [187, 70]
    }
  ];

  const shuffleArray = (array) => {
    // for (let i = array.length - 1; i > 0; i--) {
    //   const j = Math.floor(Math.random() * (i + 1));
    //   const temp = array[i];
    //   array[i] = array[j];
    //   array[j] = temp;
    // }
    return array;
  };

  const revealToggle = () => {
    setReveal(!reveal);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Container className={styles.skillsContainer}>
        <div className="d-flex justify-content-center">
          <h2
            className="text-center mb-0 fw-lighter fs-1"
            style={{ color: "#444", fontFamily: "calibri" }}
          >
            Guess my skillset{" "}
            <div className="text-center mt-1 text-secondary font-weight-lighter fs-6">
              Drag or{" "}
              <a
                href="#reveal"
                className="align-self-center pb-2 border-0"
                onClick={revealToggle}
              >
                {reveal ? "Reset All" : "Reveal All"}
              </a>
            </div>
            <hr />
          </h2>
        </div>

        <Row>
          {shuffleArray(skills).map(
            ({ name, level, start, icon, color }, index) => (
              <Col key={index} xs={12} md={6} className={styles.skill}>
                <Row>
                  <Col xs={12} className="text-center mb-2">
                    <span
                      style={{
                        color:
                          color && color.length > 0
                            ? `hsl(${color[0]}, ${color[1]}%, 30%)`
                            : "#444"
                      }}
                    >
                      {name} {icon && <span>{icon}</span>}
                    </span>
                  </Col>
                  <Col>
                    <Progress
                      critical={level}
                      start={start}
                      color={color && color.length > 0 ? color : null}
                      reveal={reveal}
                    />
                  </Col>
                  <hr />
                </Row>
              </Col>
            )
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Skills;
