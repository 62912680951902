import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { SiGithub } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import projects from "../static/data/projects";
import styles from "./Projects.module.css";
const Projects = () => {
  const [flip, setFlip] = useState(Array(projects.length).fill(false));
  const flipCard = (index, val) => () => {
    let newFlip = [...flip];
    newFlip[index] = val;
    setFlip(newFlip);
  };
  return (
    <>
      <div className="mb-4 py-5" style={{ backgroundColor: "#ECF0F1" }}>
        <Container>
          <div className="d-flex justify-content-center">
            <h2
              className="text-center mb-0 fw-lighter fs-1"
              style={{ color: "#444", fontFamily: "calibri" }}
            >
              Projects
              <hr />
            </h2>
          </div>

          <Row className="d-flex justify-content-stretch">
            {projects.length !== 0 &&
              projects.map(
                (
                  {
                    title,
                    subtitle,
                    keywords,
                    repository,
                    text,
                    username,
                    image,
                    url
                  },
                  idx
                ) => (
                  <Col
                    key={idx}
                    xs={12}
                    md={6}
                    xl={3}
                    onMouseEnter={flipCard(idx, true)}
                    onMouseLeave={flipCard(idx, false)}
                    className="d-flex align-items-stretch"
                  >
                    <Card
                      style={{ width: "18rem", backgroundColor: "#fafafa" }}
                      className="overflow-hidden w-100 mt-4"
                    >
                      {image && (
                        <img
                          className={`m-3 rounded-pill ${styles.cardImg}`}
                          variant="top"
                          src={image}
                          alt={title}
                        />
                      )}
                      <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Subtitle className="text-muted">
                          {subtitle}{" "}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2 text-muted">
                          {keywords && keywords.length > 0 && (
                            <>
                              <br />
                              <span className="text-primary">
                                {keywords.map((kw) => `#${kw}`).join(", ")}
                              </span>
                            </>
                          )}
                        </Card.Subtitle>
                        <Card.Text>{text}</Card.Text>
                      </Card.Body>
                      <div className="">
                        <a
                          href={url ? url : `#`}
                          className={`btn btn-light w-50 rounded-0 border-end ${
                            url ? "text-primary" : "disabled"
                          }`}
                        >
                          <BiLink /> Visit
                        </a>
                        <a
                          href={
                            username && repository
                              ? `https://github.com/${username}/${repository}`
                              : `#`
                          }
                          className={`btn btn-light w-50 rounded-0 border-start ${
                            username && repository ? "text-primary" : "disabled"
                          }`}
                        >
                          <SiGithub /> Github
                        </a>
                      </div>
                    </Card>
                  </Col>
                )
              )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Projects;
